import styled from "styled-components";
import bg from "../../assets/images/hero-image.png";
import bg1 from "../../assets/images/woman_sitting.png";
import bg2 from "../../assets/images/standing_guy.png";
// import saly from '../../assets/images/Saly-1.png';
// import { BsGrid1X2 } from "react-icons/bs";
import { mobile_view } from "./mobile_styles";

interface SectionProps {
  background?: boolean;
}

export const LandingPage = styled.div`
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
    height: 90vh;
  }

  //****  new index page sytling...
  .hero-section {
    width: 100%;
    /* height: 90vh; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    background-color: ${({ theme }) => theme.bg.prim_400};
    /* Small screens */
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      width: 98%;
      padding: 1px;
    }
  }

  .header-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .heading {
    width: 100%;
    /* height: 50%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    padding-top: 5rem;
    @media (max-width: 480px) {
      display: flex;
    }
  }
  .heading > h1 {
    /* font-family: "Recoleta"; */
    font-family: "Montserrat";
    align-items: center;
    justify-self: center;
    justify-content: center;
    font-size: 1.5rem;
    padding: 1rem;
    font-weight: 600;
    line-height: 2.5rem;
    color: ${({ theme }) => theme.bg.primary};
    @media (max-width: 480px) {
      display: flex;
      color: white;
      font-size: medium;
      font-family: "Hanken Grotesk", sans-serif;
    }
  }

  .heading > article {
    display: flex;
    width: 100%;
    color: ${({ theme }) => theme.bg.primary};
    font-size: 0.7rem;
    font-family: "Hanken Grotesk", sans-serif;
    font-weight: 300;
    line-height: 1.6rem;
    padding: 2rem 0rem 2rem 0rem;
    height: fit-content;
    width: fit-content;
    padding: 1rem;
    @media (min-width: 1024px) {
      font-weight: 200;
      font-size: 1rem;
      line-height: 2.2rem;
    }
    @media (max-width: 700px) {
      padding: 5px;
      width: 100%;
      /* font-size: 0.4rem; */
      /* max-height: fit-content; */
    }
  }

  .left-content {
    width: 40%;
    height: 90%;
    /* padding-top: 10%; */
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 480px) {
      z-index: 0;
      display: none;
    }
  }

  .right-content {
    width: 50%;
    height: 90%;
    display: flex;
    @media (max-width: 480px) {
      /* z-index: 100; */
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;

      .left {
        display: none;
      }
      .right {
        z-index: 1;
        width: 100%;
      }
    }
  }

  .right-content .left {
    @media (max-width: 480px) {
    }
  }

  .right-content .right {
    @media (max-width: 480px) {
      z-index: 1;
      text-align: center;
      border-radius: 12px;
      /* display: none; */
    }
  }

  /* *********** Landing mobile styles **********  */
  .mobile-card {
    position: relative;
    top: 60%;
    width: 90%;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.bg.primary},
      #2575fc
    );

    border-radius: 16px 16px 0 0;
    color: white;
    text-align: center;
    font-family: Arial, sans-serif;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.5s ease-in-out;
    display: none;
  }

  /* App Name Styling */
  .app-name {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: bold;
  }

  /* App Description Styling */
  .app-description {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.5;
    width: 80%;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: auto;
    padding: 5px;
    padding-bottom: 12px;
  }

  /* Button Styling */
  .get-started-btn {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #2575fc;
    background: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }

  .get-started-btn:hover {
    background: #2575fc;
    color: white;
  }

  /* Fade-In Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 480px) {
    .mobile-card {
      display: block; /* Display the card only on mobile screens */
    }
  }
  /* ****************** ending mobile styles ********* */

  .btn_class {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-weight: 600;
    line-height: 24px;
    height: 4rem;
    width: 100%;

    @media (max-width: 480px) {
      z-index: 100;
      display: flex;
      flex-direction: column;
    }
  }

  .btn_class > button {
    height: 2rem;
    width: 2rem;
    font-size: 60%;
    &:hover {
      transform: scale(1.05);
    }
    @media (max-width: 480px) {
      width: 100%;
      display: flex;
      gap: 2px;
      position: absolute;
    }
  }

  .btn_class > a {
    text-decoration: none;
    font-weight: 600;
    font-size: 60%;
    line-height: 20px;
    color: ${({ theme }) => theme.bg.secondary};
    border: ${({ theme }) => theme.bg.secondary};
    border: solid 1px;
    width: 7rem;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease, color 0.3s ease;
    @media (max-width: 700px) {
      display: none;
    }
  }

  .square1 {
    width: 100%;
    height: 50%;
    display: flex;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 1rem;
    background: ${({ theme }) => theme.bg.primary};
    font-family: "Hanken Grotesk", sans-serif;
  }

  .square1 > h1 {
    color: #fff;
    display: flex;
    font-size: 5rem;
    letter-spacing: 1.76px;
    text-align: center;
    font-weight: 600;
    line-height: 94px;
    font-family: "Recoleta";
  }

  .square1 > h2 {
    text-align: center;
    justify-content: center;
    color: ${({ theme }) => theme.bg.prim_300};
    font-size: 10px;
    font-family: "Hanken Grotesk", sans-serif;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: 1.76px;
  }

  .square2 {
    justify-content: center;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 4px;
    background: ${({ theme }) => theme.bg.tertiary};
  }

  .square2 > h1 {
    color: #fff;
    font-size: 5rem;
    letter-spacing: 1.76px;
    text-align: center;
    font-weight: 600;
    line-height: 94px;
    font-family: "Recoleta";
  }

  .square2 > h2 {
    text-align: center;
    justify-content: center;
    color: ${({ theme }) => theme.bg.prim_300};
    font-size: 10px;
    font-family: "Hanken Grotesk", sans-serif;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: 1.76px;
  }

  .left {
    flex: 1;
    background-color: ${({ theme }) => theme.bg.prim_300};
    width: 50%;
  }

  .right {
    width: 50%;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  @media screen and(max-width: 600px) {
  }
`;

export const Section = styled.div<SectionProps>`
  @media (max-width: 480px) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .Section2design {
    width: 100%;
    height: 24rem;
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    display: none;
    @media (max-width: 480px) {
      width: 100%;
      height: 10rem;
    }
  }

  .jeticon {
    width: 20rem;
    height: 15rem;
    left: 40%;
    position: absolute;
    &:hover {
      transform: scale(1.05);
    }
    @media (max-width: 480px) {
      margin-top: 0px;
      width: 10rem;
      height: 10rem;
    }
  }

  .dot_icon {
    position: absolute;
    left: 5%;
    margin-top: 4rem;
    width: 12rem;
    height: 12rem;
    @media (max-width: 480px) {
      position: absolute;
      bott: 7rem;
      margin-top: 0px;
      width: 10rem;
      height: 10rem;
    }
  }

  /* ***************** End Section ************* */

  .medEx_user {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    margin: 2rem;
    margin-top: 10rem;
    @media (max-width: 480px) {
      padding: 1rem;
      margin: 1rem;
      margin-top: 10rem;
      justify-items: auto;
    }
  }

  .medEx_user > h2 {
    color: ${({ theme }) => theme.text.secondary};

    font-size: 16px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    @media (max-width: 480px) {
      margin-top: -1rem;
      color: ${({ theme }) => theme.bg.secondary};
      /* font-size: 1rem; */
      font-weight: 400;
      line-height: 2rem;
    }
  }

  .medEx_user > p {
    align-items: center;
    justify-content: center;
    align-self: center;
    color: ${({ theme }) => theme.text.prim_50};
    font-size: 35px;
    font-family: "Recoleta";
    font-style: normal;
    font-weight: 600;
    /* line-height: 3rem; */
    line-height: 45.5px;
    text-align: center;
    width: 60%;
    height: 60px;

    @media (max-width: 480px) {
      display: flex;
      color: ${({ theme }) => theme.text.prim_50};
      font-size: 1rem;
      font-family: "Recoleta";
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
      text-align: center;
      width: 80%;
      padding-left: 5px;
      padding-right: 5px;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      max-width: 30%;
      line-height: 1.5rem;
    }
  }

  .medEx_user > article {
    line-height: 24px;
    font-weight: 400;
    font-family: "Montserrat";
    font-size: 16px;
    margin-bottom: 2rem;
    width: 55%;
    height: max-content;
    text-align: center;
    margin-top: 4.5rem;

    /* @media (min-width: 1024px) {
      margin-top: 4%;
    } */
    /* @media (min-width: 481px) and (max-width: 1024px) {
      font-size: 0.8rem;
      width: 60%;
    } */

    @media (max-width: 480px) {
      font-size: 10px;
      max-width: 30%;
      /* padding: 10px 10px 20px 20px; */
      text-align: justify;
      margin-top: 5px;
    }
  }

  .buttonArray {
    @media (max-width: 480px) {
      width: 0px;
      display: none;
    }
  }

  /* **************** Section ****************** */
  .middle_section_container {
    place-items: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.bg.prim_500};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7rem;
    padding-bottom: 8rem;
    margin: 5rem;
    margin: auto;
    @media (max-width: 480px) {
      width: auto;
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
    }
  }

  .mid2_container2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    margin: auto;
    padding-left: 20rem;
    padding-right: 20rem;
    margin: 20px;

    @media (min-width: 600px) and (max-width: 1024px) {
    }
    @media (max-width: 480px) {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .sqr1 {
    width: 10rem;
    height: 8rem;
    gap: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    transition: transform 0.2s ease-in-out;
    border-radius: 10px;
    margin: auto;

    @media (min-width: 600px) and (max-width: 1024px) {
      width: 4rem;
      height: 2rem;
    }
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 3px solid;
      &:hover {
        /* transform: scale(1.05); */
        transform: translateY(-5px);
      }
    }
  }
  .sqr1 > p {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    @media (max-width: 480px) {
      display: flex;
      gap: 2px;
      font-size: medium;
      background-color: ${({ theme }) => theme.bg.prim_400};
    }
  }
  .sqr1 > h3 {
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    line-height: normal;
  }

  .combo {
    /* position: relative;
    margin-bottom: 7.7rem; */
    @media (max-width: 480px) {
      justify-content: center;
      align-items: center;
      /* position: absolute; */
      /* left: 10rem; */
      /* bottom: 10px; */
    }
  }

  .elipse,
  .img_medSup {
    /* position: absolute; */
  }

  .elipse {
    width: 6rem;
    height: 6rem;
  }

  .img_medSup {
    width: 5rem;
    height: 5rem;
    left: 2.5rem;
    top: 2rem;
  }

  .sqr1,
  .sqr3,
  .sqr4,
  .card {
    display: flex;
    flex-direction: column;
    width: 30rem;
    height: 20rem;
    gap: 10px;
    @media (max-width: 480px) {
      max-width: 95%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      align-content: center;
      padding: 30px;
    }
    p {
      align-items: center;
      text-align: center;
      text-justify: center;
    }
  }

  /* ******* say goodby to missing drugs ****** */
  .whyUseMedEx_container {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    gap: 1rem;
    width: 80%;
    height: 60rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 12rem;
    background-color: ${({ theme }) => theme.bg.prim_400};
    /* } */

    @media (max-width: 480px) {
      margin-bottom: 10rem;

      margin-top: 1rem;
      padding-top: 1rem;
    }
  }

  .whyUseMedEx_container_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    color: ${({ theme }) => theme.text.prim_50};
    text-align: center;
    line-height: 65.5px;
    @media (max-width: 480px) {
      margin-top: 30%;
      width: 40%;
      margin-bottom: 10rem;
      font-size: 2rem;
      line-height: 50px;
      font-weight: 600;
    }
  }

  .whyUseMedEx_container_header > h2 {
    font-family: Recoleta;
    font-weight: 600;
    /* font-size: 3rem; */
    font-size: 35px;
    @media (max-width: 480px) {
      font-size: 2rem;
    }
  }

  .vector_line1 {
    width: 100px;
    display: none;
    @media (max-width: 480px) {
      width: 100px;
      display: flex;
      display: none;
    }
  }

  .vector_line2 {
    position: absolute;
    width: 16rem;
    /* display: none; */
    @media (max-width: 480px) {
      width: 12rem;
      /* display: none; */
    }
  }

  .vector_doc_icon {
    /* position: absolute; */
    display: none;
    @media (max-width: 480px) {
      display: none;
      /* width: 20px;
      height: 20px; */
    }
  }

  .whyUseMedEx_container_2 {
    margin-top: 4rem;
    width: 60rem;
    height: 30rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem;
    @media (max-width: 480px) {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 80%;
      gap: 2px;
    }
  }

  .verify_con {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .card {
    gap: 0rem;
    padding: 2rem 2rem 2rem 2rem;
    height: 10rem;
    margin: 10px;
    display: flex;
    align-items: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .card > p {
    font-size: 1rem;
    padding-left: 2rem;
    text-align: left;
    @media (max-width: 480px) {
      font-size: 0%.5;
      padding-left: 0;
      max-width: 90%;
      text-align: center;
      width: 80%;
    }
  }

  .whyUseMedEx_container_actions {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    width: 50%;
    height: 30rem;
    @media (max-width: 480px) {
      padding-top: 2rem;
      top: 10rem;
    }
  }

  .whyUseMedEx_container_actions > Button {
    height: 3rem;
    width: 90%;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
    @media (max-width: 480px) {
      display: none;
    }
  }

  /* ****************** End Say goodbye to missing drug section ******************* */

  /* ********* Inventary management ********* */
  .invent_mgr {
    width: 100%;
    height: 50rem;
    height: 100vh;
    margin-top: 10rem;
    padding: 5% 10% 5% 10%;
    display: flex;
    flex-direction: row;
    gap: 5%;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20rem;
    @media (max-width: 480px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .invent_mgr_left {
    width: 42rem;
    width: 80%;
    height: 50rem;
    background-image: url(${bg1});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* position: absolute; */
    /* left: 10%; */
    @media (max-width: 480px) {
      position: absolute;
      left: 5%;
      position: relative;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }

  .square_mgr {
    justify-content: center;
    width: 20rem;
    height: 24rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 4px;
    background: ${({ theme }) => theme.bg.primary};
    position: absolute;
    left: 25%;
    bottom: -7rem;
    @media (max-width: 480px) {
      padding: 5px;
      width: 12rem;
      height: 15rem;
      position: absolute;
      left: 25rem;
      font-family: Recoleta;
      bottom: -2rem;
    }
  }

  .square_mgr > h1 {
    color: ${({ theme }) => theme.bg.prim_500};
    font-size: 6rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.76px;
    text-align: center;
    font-weight: 600;
    line-height: 94px;
    font-family: Recoleta;
    @media (max-width: 480px) {
      font-size: 4.5rem;
    }
  }

  .square_mgr > h2 {
    text-align: center;
    justify-content: center;
    color: ${({ theme }) => theme.bg.prim_500};
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.76px;
    font-family: "Hanken Grotesk", sans-serif;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  .invent_mgr_cont {
    width: 100px;
    height: 100px;
  }

  .invent_mgr_right {
    width: 50%;
    width: 70rem;
    /* height: 40rem; */
    margin-right: 15%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.bg.prim_400};
    @media (min-width: 600px) and (max-width: 1024px) {
      display: none;
    }
    @media (max-width: 690px) {
      display: none;
    }
  }

  .invent_mgr_right > h2 {
    color: ${({ theme }) => theme.text.secondary};
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 5rem;
    width: fit-content;
    left: 0px;
    font-size: 16px;
    @media (max-width: 480px) {
      /* display: none; */
    }
  }

  .invent_mgr_right > p {
    color: ${({ theme }) => theme.text.prim_50};
    font-size: 1.8rem;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 600;
    line-height: 2.8rem;
    width: fit-content;
    text-align: center;

    @media (max-width: 480px) {
      /* display: none; */
    }
  }

  .invent_mgr_right > article {
    /* padding: 1rem 0rem 3rem 0rem; */
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 2rem;
    width: 60%;
    font-weight: 500;
    width: fit-content;
    @media (max-width: 480px) {
      /* display: none; */
    }
  }

  .vector_line3 {
    /* display: none; */
    @media (max-width: 480px) {
      /* display: none; */
    }
  }

  .invent_mgr_right > a {
    text-decoration: none;
    font-weight: 900;
    @media (max-width: 480px) {
      display: none;
    }
  }

  /* ********** Our thinking ************ */

  .our_thinking {
    display: flex;
    flex-direction: row;
    margin-top: 10rem;
    width: 100%;
    height: 40rem;
    background: ${({ theme }) => theme.bg.prim_400};
    position: relative;
    margin-bottom: 10rem;

    @media (max-width: 480px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  .think_left_text {
    width: 27rem;
    height: 30rem;
    /* position: absolute; */
    align-items: center;
    justify-content: center;
    /* left: 24%; */
    margin-left: 20%;
    top: 6rem;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    @media (min-width: 480px) and (max-width: 1024px) {
      position: absolute;
      left: 10%;
    }
    @media (max-width: 480px) {
      position: absolute;
      left: 7%;
      top: 17%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      max-width: 30%;
      padding: 20px;
      padding-left: 3rem;
      text-align: left;
    }
  }

  .think_left_text > h2 {
    color: ${({ theme }) => theme.text.secondary};
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 5rem;
    width: fit-content;
    left: 1px;
    @media (max-width: 480px) {
      left: 0px;
      margin-top: 2rem;
      font-size: 1rem;
    }
  }

  .think_left_text > p {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    color: ${({ theme }) => theme.text.prim_50};
    font-size: 2.5rem;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 600;
    line-height: 3rem;
    width: 100%;
    padding: 0rem;
    @media (max-width: 480px) {
      margin-top: 0%;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      width: 60%;
      font-size: 1.4rem;
      line-height: 1.5rem;
    }
  }

  .think_left_text > article {
    margin-top: 2.2rem;
    line-height: 2rem;
    font-weight: 500;
    width: fit-content;
    @media (max-width: 480px) {
      margin-top: 0.5rem;
      font-size: 0.7rem;
      width: 12rem;
      line-height: 1.3rem;
      padding-left: 1rem;
      /* display: none; */
    }
  }

  .think_right_img {
    width: 35rem;
    height: 45rem;
    background-image: url(${bg2});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    right: 12%;
    bottom: 0rem;
    @media (min-width: 500px) and (max-width: 1024px) {
      right: 14%;
    }
    @media (max-width: 480px) {
      width: 23rem;
      height: 40rem;
      right: 19%;
    }
  }

  /* ************** Intuitive Dashboard ************** */

  .intuitive_dashboard {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30rem;
    margin-top: 30rem;
    margin-bottom: 15rem;
    /* background: linear-gradient(
      to top,
      ${({ theme }) => theme.bg.primary},
      #ffffff
    ); */
    @media (max-width: 480px) {
      margin-top: 6rem;
      height: 350px;
      max-width: 100%;
      padding-right: 4rem;
      padding-left: 4rem;
      /* background-color: ${({ theme }) => theme.text.secondary}; */
    }
  }

  .intuitive_dashboard > h2 {
    color: ${({ theme }) => theme.text.secondary};
    font-size: 1.5rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 5rem;
    width: fit-content;
    left: 0px;
    @media (max-width: 480px) {
      margin-top: 4rem;
      font-size: 1rem;
    }
  }

  .intuitive_dashboard > p {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    color: ${({ theme }) => theme.text.prim_50};
    font-size: 2.5rem;
    /* font-family: "Recoleta"; */
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 600;
    line-height: 3.4rem;
    width: 55rem;
    margin-bottom: 1rem;
    text-align: center;
    width: fit-content;
    left: 0px;
    @media (max-width: 480px) {
      margin-top: 0%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.2rem;
      width: 40%;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .intuitive_dashboard > article {
    font-family: "Montserrat";
    line-height: 35.5px;
    size: 16px;
    font-weight: 500;
    padding-bottom: 30px;
    width: 45%;
    text-align: center;
    @media (max-width: 480px) {
      font-size: 0.7rem;
      line-height: 1rem;
      width: 30%;
    }
  }

  .intuitive_dashboard > img {
    width: 40rem;
    height: 40rem;
    @media (max-width: 480px) {
      width: 15rem;
      height: 15rem;
      align-items: center;
      /* margin: 2rem; */
    }
  }

  /* ************** testimonials_section ************** */
  .testimonials_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50rem;
    margin-top: 10rem;
    position: relative;
    display: none;
    @media (max-width: 480px) {
      margin-top: 1rem;
      height: 30rem;
      display: none;
    }
  }

  .testimonials_section > h2 {
    color: ${({ theme }) => theme.text.secondary};
    font-size: 1.5rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 5rem;
    width: fit-content;
    left: 0px;
    @media (max-width: 480px) {
      margin-top: 0rem;
      font-size: 1rem;
    }
  }

  .testimonials_section > p {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    color: ${({ theme }) => theme.text.prim_50};
    font-size: 2.5rem;
    font-family: Recoleta;
    font-style: normal;
    font-weight: 600;
    line-height: 3rem;
    width: 55rem;
    margin-bottom: 6rem;
    text-align: center;
    @media (max-width: 480px) {
      margin-bottom: 1rem;
      max-width: 50%;
      width: 50%;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  .testimonia_line {
    position: absolute;
    top: 17%;
    right: 37%;
    width: 12rem;
    @media (max-width: 480px) {
      position: absolute;
      top: 4rem;
      display: none;
    }
  }

  /* ******************** */

  /* new carousel style  */
  .testimonial_slidder {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: ${({ theme }) => theme.bg.prim_400};
    height: 25rem;
    margin-bottom: 3rem;
    gap: 20px;
  }

  .test_caro {
    height: 20rem;
    width: 60rem;
    @media (max-width: 480px) {
      max-width: 40%;
      padding: 20px;
    }
  }

  .caro {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .testimonial_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
    color: ${({ theme }) => theme.text.prim_50};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    @media (max-width: 480px) {
      text-align: center;
      border-radius: 10px;
    }
  }

  /* Testimonial Content */
  .testimonial_card article {
    font-size: 12px;
    margin-bottom: 20px;
    padding: 5px;
    @media (max-width: 480px) {
      text-align: center;
      font-size: 8px;
    }
  }

  /* Testimonial ID Section */
  .test_id {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  /* Testimonial Image */
  .tes_img {
    border-radius: 50%;
  }

  /* Author Section */
  .author {
    display: grid;
    flex-direction: column;
    text-align: center;
    padding: 2px;
    padding-left: 12px;
    white-space: nowrap;
    gap: 0;

    @media (max-width: 480px) {
      padding: 0px;
      padding-right: 10px;
    }
  }

  .author h3 {
    font-size: 12px;
    margin: 0;
    color: #333;
  }

  .author p {
    font-size: 10px;
    margin: 0;
    color: #888;
  }

  /* Quote Image */
  .quote {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  /* ********* Last Button ********* */
  .btn_class2 > button {
    height: 5rem;
    width: 2rem;
    font-size: 1.4rem;
    background: rgba(102, 145, 255, 0.1);
    margin-bottom: 5rem;
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      width: 100px;
      margin: 2px;
    }
  }

  h2 {
    /* font-style: normal;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.625rem;
    text-align: center; */
    /* color: ${({ theme }) => theme.text.prim_300}; */

    @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
      font-weight: 500;
    }
  }

  p {
    /* width: 100%;
    padding: 0 ${({ background }) => (background ? "10vw" : "22vw")};
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.5rem;
    text-align: center; */
    color: ${({ theme }) => theme.text.prim_400};
    margin-top: 1rem;

    @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
      font-weight: 400;
      margin-top: 0.4rem;
      padding: 1rem 5vw;
    }
  }

  .cards {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
  }
`;

export const Vision = styled.div`
  width: 100%;
  padding: 6.25rem;
  background-color: ${({ theme }) => theme.bg.prim_100};

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    font-weight: 400;
    margin-top: 0.4rem;
    padding: 4rem 5vw;
  }

  .vision-container {
    width: 100%;
    background-color: ${({ theme }) => theme.bg.primary};
    padding: 1.9563rem 6.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      font-weight: 400;
      margin-top: 0.4rem;
      padding: 4rem 1rem;
    }

    h2 {
      background-color: ${({ theme }) => theme.bg.secondary};
      padding: 0 1.1875rem;
      color: ${({ theme }) => theme.text.inverted};
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 4rem;
      font-feature-settings: "liga" off;
      margin-bottom: 1.5rem;

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 2rem;
        font-weight: 500;
      }
    }

    p {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 175%;
      text-align: center;
      color: ${({ theme }) => theme.text.prim_300};

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 1.3rem;
      }
    }
  }
`;

export const Partners = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4.25rem 6.25rem 7.625rem 6.25rem;
  gap: 2.2rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    align-items: center;
    padding: 4.25rem 2rem 7.625rem 2rem;
  }

  h2 {
    background-color: ${({ theme }) => theme.bg.secondary};
    padding: 0 3.0625rem;
    color: ${({ theme }) => theme.text.inverted};
    font-feature-settings: "liga" off;
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 2rem;
    line-height: 4rem;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .partner-logos {
    width: 100%;
    display: flex;
    align-items: space-around;
    justify-content: space-around;
    gap: 1rem;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    }

    img {
      height: 9.375rem;
      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        height: 5rem;
      }
    }
  }
`;

export const Notify = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4.5rem;
  padding: 7rem 5vw;
  background-color: ${({ theme }) => theme.bg.secondary};

  h2 {
    width: 31.75rem;
    color: ${({ theme }) => theme.text.inverted};
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.625rem;
    text-align: center;
  }

  .notify-input {
    width: 32rem;
    height: 5.5625rem;
    background-color: ${({ theme }) => theme.bg.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0;
    overflow: hidden;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      height: 4rem;
    }

    input {
      height: 100%;
      width: 70%;
      background-color: transparent;
      border: none;
      font-size: 2.25rem;
      padding: 0 1rem;
    }

    button {
      width: 30%;
      height: 100%;
      color: ${({ theme }) => theme.text.inverted};
      background-color: ${({ theme }) => theme.bg.inverted};
      font-weight: 400;
      font-size: 1.375rem;
      line-height: 1.375rem;
      border: none;
      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 1.1rem;
      }
    }
  }
  ${mobile_view}
`;
