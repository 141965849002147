import { Button } from "src/common/components/button";
import { DeliveryWrapper } from "./styles";
import Table from "src/common/components/Table";
import { useNavigate } from "react-router-dom";
import useQuery from "src/hooks/useQuery";
import {
  DELIVERIES,
  DELIVERY_REQUEST_URLS,
  LOGISTICS_APP_BASE_URL,
} from "src/common/constants";
import Tab2 from "src/common/components/Tab/Tab2";
import { useCallback, useState } from "react";
import {
  getIncomingTableData,
  getSentTableData,
  inComingTableHeaders,
  incomingTableKeys,
  sentTableHeaders,
  sentTableKeys,
} from "./data";
import { Pagination } from "src/common/components/pagination";
import { KeyValueObj } from "src/common/types";
import { Modal } from "src/common/components/modal";
import { DeliveryRequestView } from "./delivery-view";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { PageTitleNavs } from "src/common/components/page-title-navs";

export interface IDelivery {}

export function Delivery(props: IDelivery) {
  const [incomingCurrentPage, setIncomingCurrentPage] = useState(0);
  const [outgoingCurrentPage, setOutgoingCurrentPage] = useState(0);
  const [outgoingPerpage, setOutgoingPerpage] = useState(25);
  const [incomingPerpage, setIncomingPerpage] = useState(25);
  const [currentTab, setCurrentTab] = useState("Incoming Delivery");
  const [currentRow, setCurrentRow] = useState<KeyValueObj>({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentDeliveryData, setCurrentDeliveryData] = useState<KeyValueObj>(
    {}
  );
  const [showViewModal, setShowViewModal] = useState(false);
  const [accepting, setAccepting] = useState(false);

  const navigate = useNavigate();
  const {
    data: incomingRequests,
    meta: incomingRequestMeta,
    loading: incomingLoading,
    refetch: refetchIncomingRequests,
  } = useQuery(
    DELIVERY_REQUEST_URLS +
      `/incoming-requests?per_page=${incomingPerpage}&page=${incomingCurrentPage}`
  );
  const {
    data: outgoingRequests,
    meta: outgoingRequestMeta,
    loading: outgoingLoading,
    refetch: refetchOutgoingRequests,
  } = useQuery(
    DELIVERY_REQUEST_URLS +
      `/outgoing-requests?per_page=${outgoingPerpage}&page=${outgoingCurrentPage}`
  );

  const makeRequest = useApiCallHandling();

  const handleDropdownClick = useCallback(
    (row: KeyValueObj) => {
      console.log({ row });
      showDropdown ? setCurrentRow({}) : setCurrentRow(row);
      setShowDropdown((prev) => !prev);
    },
    [showDropdown]
  );

  const handleOutgoinView = useCallback(() => {
    const deliveryData = outgoingRequests?.find(
      (req: KeyValueObj) => req?.delivery_request?.id === currentRow?.id
    );
    setCurrentDeliveryData(deliveryData);
    setShowViewModal(true);
  }, [currentRow?.id, outgoingRequests]);

  const handleIncomingView = useCallback(() => {
    const deliveryData = incomingRequests?.find(
      (req: KeyValueObj) => req?.delivery_request?.id === currentRow?.id
    );
    setCurrentDeliveryData(deliveryData);
  }, [currentRow?.id, incomingRequests]);

  const handleAcceptSucess = useCallback(() => {
    setShowViewModal(false);
    refetchOutgoingRequests();
    refetchIncomingRequests();
  }, [refetchIncomingRequests, refetchOutgoingRequests]);

  const handleAcceptDeliveryFee = useCallback(() => {
    setAccepting(true);
    makeRequest(
      DELIVERIES + `/${currentDeliveryData?.delivery?.id}/approve`,
      "POST",
      {},
      handleAcceptSucess,
      console.log,
      () => setAccepting(false)
    );
  }, [currentDeliveryData?.delivery?.id, handleAcceptSucess, makeRequest]);

  const trackDelivery = useCallback(() => {
    window.open(
      LOGISTICS_APP_BASE_URL +
        `tracking?tracking_id=${currentRow?.trackingID?.value}`
    );
  }, [currentRow]);

  const tabs = [
    {
      title: "Incoming Delivery",
      component: (
        <div className="table-wrapper">
          <Table
            tableHeaders={inComingTableHeaders}
            tableKeys={incomingTableKeys}
            tableData={getIncomingTableData(incomingRequests)}
            tableTitle="Incoming Deliveries"
            tableSubtitle={
              "Total incoming deliveries: " +
              (incomingRequestMeta?.total_count ?? "--")
            }
            loading={incomingLoading}
            handleDropDownClicked={handleDropdownClick}
            showDropDown={showDropdown}
            dropDownOptions={[
              { label: "View", action: handleIncomingView },
              { label: "Track", action: trackDelivery },
            ]}
            spaced
            rowLineColor="light"
          />
          <Pagination
            pageCount={incomingRequestMeta?.total_pages}
            onNextPage={(event: any) => setIncomingCurrentPage(event.selected)}
            onPerPageChange={(event: any) =>
              setIncomingPerpage(event?.target?.value)
            }
          />
        </div>
      ),
    },
    {
      title: "Sent Delivery",
      component: (
        <div className="table-wrapper">
          <Table
            tableHeaders={sentTableHeaders}
            tableKeys={sentTableKeys}
            tableData={getSentTableData(outgoingRequests ?? [])}
            tableTitle="Sent Deliveries"
            tableSubtitle={
              "Total sent deliveries: " +
              (outgoingRequestMeta?.total_count ?? "--")
            }
            loading={outgoingLoading}
            handleDropDownClicked={handleDropdownClick}
            showDropDown={showDropdown}
            dropDownOptions={[
              { label: "View", action: handleOutgoinView },
              { label: "Track", action: trackDelivery },
            ]}
            spaced
            rowLineColor="light"
          />
          <Pagination
            pageCount={outgoingRequestMeta?.total_pages}
            onNextPage={(event: any) => setOutgoingCurrentPage(event.selected)}
            onPerPageChange={(event: any) =>
              setOutgoingPerpage(event?.target?.value)
            }
          />
        </div>
      ),
    },
  ];

  return (
    <DeliveryWrapper>
      <PageTitleNavs
        title="Delivery Management"
        backLink="#"
        bcrumbs={[{ title: "Deliveries", link: "#" }]}
      />
      <div className="top-wrapper">
        <Button onClick={() => navigate("new-delivery-request")}>
          New Delivery
        </Button>
      </div>

      <Tab2
        onTabClick={(tab: string) => setCurrentTab(tab)}
        currentTab={currentTab}
        tabs={tabs}
      />

      <Modal
        title="Delivery Request Details"
        isOpen={showViewModal}
        close={() => setShowViewModal(false)}
      >
        <DeliveryRequestView
          deliveryData={currentDeliveryData}
          handleAcceptDeliveryFee={handleAcceptDeliveryFee}
          accepting={accepting}
        />
      </Modal>
    </DeliveryWrapper>
  );
}
