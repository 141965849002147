import message from "../../assets/images/Message.png";
import mobile from "../../assets/images/Mobile.png";
import location from "../../assets/images/Location.png";

export const quickLinks = [
  {
    text: "Portfolio",
    link: "#",
  },
  {
    text: "Blogs",
    link: "#",
  },
  {
    text: "About",
    link: "#",
  },
  {
    text: "Careers",
    link: "#",
    more: "we're hiring",
  },
];

export const services = [
  {
    text: "Drug Supply Management",
    link: "#",
  },
  {
    text: "Inventory Management ",
    link: "#",
  },
  {
    text: "Support Regulatory Compliance",
    link: "#",
  },
  {
    text: "Real-time Tracking ",
    link: "#",
  },
  {
    text: "Predictive Analytics ",
    link: "#",
  },
  {
    text: "Secure Data Management ",
    link: "#",
  },
  {
    text: "Online Ordering and Delivery",
    link: "#",
  },
  {
    text: "Payment Solutions ",
    link: "#",
  },
];

export const reachUs = [
  {
    text: "info@medex.ng",
    link: "#",
    icon: message,
  },
  {
    text: "+2348037232457",
    link: "#",
    icon: mobile,
  },
  {
    text: "2nd Floor, ASTA GALLERY Plot 1185, Parkway Road, Cadastral Zone, B06 Beside Ministry of Works & Housing, Mabushi District, Abuja FCT. Nigeria.",
    link: "#",
    icon: location,
  },
];
