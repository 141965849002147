import { KeyValueObj } from "src/common/types";
import { formatKey } from "src/common/utils/general";

export const productDetailsForm = [
  {
    name: "name",
    prompt: "Product Name",
    placeholder: "Panadol",
    inputType: "text",
    fullWidth: false,
    shortWidth: false,
    rules: {
      minLength: {
        expectedValue: 2,
        errorMessage: "Product name should be atleast two characters",
      },
    },
  },
  {
    name: "description",
    prompt: "Description",
    placeholder: "Light green box of clean Penicilin bottles",
    isFullWidth: false,
    inputtype: "textarea",
  },
  {
    name: "value",
    prompt: "What is the estimated monetary value of this item in naira",
    inputType: "number",
    placeholder: "30000",
  },

  {
    name: "category",
    prompt: "Category",
    inputType: "selectInput",
    placeholder: "Refrigerated",
    returnId: true,
    options: [
      { title: "Cold Storage", value: "cold_storage", id: "cold_storage" },
      {
        title: "Non Cold Storage",
        value: "non_cold_storage",
        id: "non_cold_storage",
      },
    ],
  },
  {
    name: "weight",
    prompt: "Product Weight(kg)",
    inputType: "number",
    placeholder: "400",
    fullWidth: false,
    shortWidth: false,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Weight is required",
      },
    },
  },
  {
    name: "length",
    prompt: "Package Length(cm))",
    inputType: "number",
    placeholder: "20",
    fullWidth: false,
    shortWidth: false,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Length is required",
      },
    },
  },
  {
    name: "width",
    prompt: "Package Width(cm)",
    inputType: "number",
    placeholder: "400",
    fullWidth: false,
    shortWidth: false,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Width is required",
      },
    },
  },
  {
    name: "height",
    prompt: "Package Height(cm)",
    inputType: "number",
    placeholder: "400",
    fullWidth: false,
    shortWidth: false,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Height is required",
      },
    },
  },
  {
    name: "package_type",
    prompt: "Packaging Type",
    inputType: "selectInput",
    placeholder: "Carton",
    fullWidth: false,
    shortWidth: false,
    options: [
      {
        title: "Carton",
        value: "carton",
      },
      {
        title: "Pack",
        value: "pack",
      },
    ],
  },
  {
    name: "quantity",
    prompt: "Quantity",
    placeholder: "50",
    inputType: "number",
    shortWidth: true,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Quantity is required",
      },
    },
  },
];

export const addedProductHeaders = [
  "Name",
  "Description",
  "Estimated Value",
  "Category",
  "Weight",
  "Length",
  "Width",
  "Height",
  "Packaging Type",
  "Quntity",
];

export const addedProductKeys = [
  "name",
  "description",
  "value",
  "category",
  "weight",
  "width",
  "length",
  "height",
  "package_type",
  "quantity",
];

export const getAddedProductTableBody = (products: KeyValueObj[]) =>
  products?.map((product: KeyValueObj) => ({
    id: product?.id,
    name: {
      type: "string",
      value: product?.name,
    },
    description: {
      type: "string",
      value: product?.description,
    },
    value: {
      type: "string",
      value: product?.value,
    },
    category: {
      type: "string",
      value: product?.category,
    },
    weight: {
      type: "string",
      value: product?.weight,
    },
    length: {
      type: "string",
      value: product?.length,
    },
    width: {
      type: "string",
      value: product?.length,
    },
    height: {
      type: "string",
      value: product?.height,
    },
    package_type: {
      type: "string",
      value: product?.package_type,
    },
    quantity: {
      type: "string",
      value: product?.quantity,
    },
  }));

export const getReceivingCompanyOptions = (companies: KeyValueObj[]) =>
  companies?.map((company: KeyValueObj) => ({
    title: company?.name,
    value: company?.id,
    id: company?.id,
  }));

export const getDeliveryCompanyOptions = (companies: KeyValueObj[]) =>
  companies?.map((company: KeyValueObj) => ({
    title: company?.name || "--",
    value: company?.id,
    id: company?.id,
  }));

export const getTotalWeight = (addedProducts: KeyValueObj[]) =>
  addedProducts?.reduce(
    (acc, curr) => acc + parseFloat(curr?.weight) * parseFloat(curr?.quantity),
    0
  );

export const getTotalRange = (
  addedProducts: KeyValueObj[],
  logisticCompany: KeyValueObj
) => {
  const totalWeight = getTotalWeight(addedProducts);
  const lowest = logisticCompany.lowest_price * totalWeight;
  const highest = logisticCompany.highest_price * totalWeight;
  if (isNaN(lowest) && isNaN(highest)) return "-- - --";
  return `₦${lowest} - ₦${highest}`;
};

export function getRecipentViewDetails(company: KeyValueObj) {
  return [
    {
      title: "Company Name",
      value: company?.company_name,
    },
    {
      title: "Company Phone Number",
      value: company?.phone_number,
    },
    {
      title: "Company Secondary Phone Number",
      value: company?.secondary_phone_number,
    },

    {
      title: "Company Email",
      value: company?.email,
    },
  ];
}
export function getRecipentPreviewDetails(
  company: KeyValueObj,
  address: KeyValueObj
) {
  return [
    {
      title: "Company Name",
      value: company?.name,
    },
    {
      title: "Company RC Number",
      value: company?.rc_number,
    },
    {
      title: "Company Phone Number",
      value: company?.phone_number,
    },
    {
      title: "Company Email",
      value: company?.email,
    },
    {
      title: "Delivery Address",
      value: address?.address_line_one + ", " + address?.address_line_two,
    },
    {
      title: "Delivery City",
      value: address?.city,
    },
    {
      title: "Delivery LGA",
      value: address?.lga,
    },
    {
      title: "Delivery State",
      value: address?.state,
    },
  ];
}

export function getLogisticViewDetails(company: KeyValueObj) {
  return [
    {
      title: "Company Name",
      value: company?.name,
    },
    {
      title: "Company Phone Number",
      value: company?.phone_number,
    },
    {
      title: "Company Email",
      value: company?.email,
    },
    {
      title: "Address",
      value: `${company.street_address || "--"}, ${company.state || "--"}`,
    },
  ];
}
export function getLogisticPreviewDetails(
  company: KeyValueObj,
  addedProducts: KeyValueObj[]
) {
  return [
    {
      title: "Company Name",
      value: company?.name,
    },
    {
      title: "Company Phone Number",
      value: company?.phone_number,
    },
    {
      title: "Company Email",
      value: company?.email,
    },
    {
      title: "Address",
      value: `${company.street_address || "--"}, ${company.state || "--"}`,
    },
    {
      title: "Price Range (kg)",
      value: `${company.lowest_price || "--"} - ${
        company.highest_price || "--"
      }`,
    },
    {
      title: "Total Delivery Weigth",
      value: getTotalWeight(addedProducts),
    },
    {
      title: "Total Cost Range",
      value: getTotalRange(addedProducts, company),
    },
  ];
}

export function getPickupPreviewDetails(
  pickupOption: string,
  senderAddressType: string,
  address: KeyValueObj
) {
  if (pickupOption === "sender_drop_off")
    return [
      {
        title: "Pickup Type",
        value: "Logistic Pickup",
      },
    ];

  return [
    {
      title: "Pickup Type",
      value: formatKey(pickupOption),
    },
    {
      title: "Address Type",
      value: formatKey(senderAddressType),
    },
    {
      title: "Sender Address",
      value: address?.address_line_one + ", " + address?.address_line_two,
    },
    {
      title: "Sender City",
      value: address?.city,
    },
    {
      title: "Sender LGA",
      value: address?.lga,
    },
    {
      title: "Sender State",
      value: address?.state,
    },
  ];
}

export const getOtherDetails = (details: KeyValueObj) => [
  {
    title: "Preffered Vehicle",
    value: details?.vehicle_needed,
  },
  {
    title: "Sender's Secondary Phone Number",
    value: details?.sender_phone_number,
  },
  {
    title: "Recipient's Secondary Phone Number",
    value: details?.recipent_phone_number,
  },
];

export const vehicleOptions = [
  {
    title: "Bus",
    value: "bus",
    id: "bus",
  },
  {
    title: "Truck",
    value: "truck",
    id: "truck",
  },
  {
    title: "Van",
    value: "van",
    id: "van",
  },
  {
    title: "Car",
    value: "car",
    id: "car",
  },
  {
    title: "Refrigerated",
    value: "refrigerated",
    id: "refrigerated",
  },
  {
    title: "Other",
    value: "other",
    id: "other",
  },
];

export const inComingTableHeaders = [
  "Tracking Number",
  "Sending Company",
  "Logistic Company",
  "Request Status",
  "Delivery Status",
];

export const sentTableHeaders = [
  "Tracking Number",
  "Receiving Company",
  "Logistic Company",
  "Request Status",
  "Delivery Status",
];

export const incomingTableKeys = [
  "trackingID",
  "sendingCompany",
  "logisticCompany",
  "requestStatus",
  "deliveryStatus",
];
export const sentTableKeys = [
  "trackingID",
  "receivingCompany",
  "logisticCompany",
  "requestStatus",
  "deliveryStatus",
];

export function getIncomingTableData(data: KeyValueObj[]) {
  return data?.map((datum: KeyValueObj) => ({
    id: datum?.id,
    trackingID: {
      type: "string",
      value: datum?.delivery?.tracking_number,
    },
    sendingCompany: {
      type: "string",
      value: datum?.sender?.name,
    },
    logisticCompany: {
      type: "string",
      value: "",
    },
    requestStatus: {
      type: "string",
      value: datum?.delivery_request?.request_status,
    },
    deliveryStatus: {
      type: "string",
      value: datum?.delivery?.delivery_status,
    },
  }));
}
export function getSentTableData(data: KeyValueObj[]) {
  return data?.map((datum: KeyValueObj) => ({
    id: datum?.delivery_request?.id,
    trackingID: {
      type: "string",
      value: datum?.delivery?.tracking_number,
    },
    receivingCompany: {
      type: "string",
      value: datum?.delivery_request?.recipient?.name,
    },
    logisticCompany: {
      type: "string",
      value: datum?.delivery_request?.company?.name,
    },
    requestStatus: {
      type: "string",
      value: datum?.delivery_request?.request_status,
    },
    deliveryStatus: {
      type: "string",
      value: datum?.delivery?.delivery_status,
    },
  }));
}
