import { BsTwitter, BsInstagram, BsYoutube, BsFacebook } from "react-icons/bs";
import { FooterGroup } from "./FooterGroup";
import { Wrapper, IconWrappr } from "./styles";
import { quickLinks, services, reachUs } from "./data";

export interface IFooterProps {}

export function Footer(props: IFooterProps) {
  return (
    <Wrapper>
      <div className="top flex-row">
        <div className="segment flex-col">
          <h3>
            We simplified and automate pharmaceutical and medical supply chain
            with intelligent inventory management
          </h3>
          <div className="flex-row icons">
            <IconWrappr>
              <BsInstagram className="icon" />
            </IconWrappr>
            <IconWrappr>
              <BsFacebook className="icon" />
            </IconWrappr>
            <IconWrappr>
              <BsTwitter className="icon" />
            </IconWrappr>
            <IconWrappr>
              <BsYoutube className="icon" />
            </IconWrappr>
          </div>
        </div>
        <div className="segment">
          <FooterGroup title="Quick Links" items={quickLinks} />
        </div>
        <div className="segment">
          <FooterGroup title="Services" items={services} />
        </div>
        <div className="segment">
          <FooterGroup title="Reach Us" items={reachUs} />
        </div>
      </div>
      <div className=" flex-row lower">
        <h2>Copyright © 2024 MedEx</h2>
        <h2>Terms & Conditions | Privacy Policy | Sitemap | Disclaimer</h2>
      </div>
    </Wrapper>
  );
}
